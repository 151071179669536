// /src/utils/services.js
export const services = [
    {
      icon: "si1.png",
      title: "Best Quality",
      description: "Premium plywood and timber materials that meet the highest standards."
    },
    {
      icon: "si2.png",
      title: "Experienced",
      description: "Decades of expertise in the plywood and timber industry."
    },
    {
      icon: "si3.png",
      title: "Expert team",
      description: "Skilled professionals dedicated to exceptional service."
    },
    {
      icon: "si4.png",
      title: "Affordable services",
      description: "Quality solutions that fit your budget."
    },
    {
      icon: "si5.png",
      title: "Warranty",
      description: "Assurance of durability and long-lasting performance."
    },
    {
      icon: "si6.png",
      title: "Award winning",
      description: "Exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo."
    }
  ];
  