// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className="footer_style_2 footer_blog">
      <div className="container">
        <div className="row">
          
          {/* Column 1: Logo and Company Info */}
          <div className="col-md-8">
            <div className="footer-logo">
              <img src="images/logos/logo-deepak.png" className="img-fluid" alt="Deepak Plywood & Timbers Logo" />
            </div>
            <div className="company-info">
              <p><strong>Mr. Deepak Pareek, Mr. Praveen Pareek</strong></p>
              <p>Specializing in high-quality plywood, timber, and hardware products.</p>
            </div>
          </div>

          {/* Column 2: Contact Information */}
          <div className="col-md-4">
            <div className="contact-info">
              <h2>Contact Information</h2>
              <p><strong>Address:</strong> Near SBI Bank, Kanta Choraha, <br /> Jhotwara, Kalwar Road, <br /> Jaipur (Raj)</p>
              <p><strong>Mobile:</strong> +91-9314628326 / 9314628328</p>
              <p><strong>E-mail:</strong> <a href="mailto:deepakplywood@yahoo.com">deepakplywood@yahoo.com</a></p>
              <p><strong>Website:</strong> <a href="http://www.deepakplywood.com" target="_blank">www.deepakplywood.com</a></p>
            </div>
          </div>

        </div>

        {/* Footer Copyright */}
        <div className="cprt">
          <p>Designed and Developed by <a href="https://www.seventricks.com/">Seventricks.com</a>. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
