import React from 'react';
import { services } from '../utils/services';

function WhyChoiseUs() {
  return (
    <div id="about" className="section padding_layout_1 gross_layout">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="main_heading text_align_left">
                <h2>Why choose us</h2>
                <p className="large">for Deepak Plywood & Timbers</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className="row">
              {services.map((service, index) => (
                <div key={index} className="col-md-4">
                  <div className="full">
                    <div className="service_blog_inner">
                      <div className="icon text_align_left">
                        <img src={`images/layout_img/${service.icon}`} alt={service.title}  style={{padding: 8}}/>
                      </div>
                      <h4 className="service-heading">{service.title}</h4>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChoiseUs;
