import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
  // State to track the visibility of the button
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      // Show the button when scroll position is more than 300px
      if (window.scrollY > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    visible && (
      <button
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '30px',
          right: '30px',
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          padding: '10px 15px',
          borderRadius: '50%',
          cursor: 'pointer',
          fontSize: '18px',
        }}
      >
        ↑
      </button>
    )
  );
};

export default ScrollToTop;
