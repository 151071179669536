import React from 'react'

function WhatWeDo() {
  return (
    <div className="section padding_layout_1">
    <div className="container">
       <div className="row">
          <div className="col-md-12">
             <div className="full">
                <div className="main_heading text_align_center">
                   <h2><span>What we do</span></h2>
                </div>
             </div>
          </div>
       </div>
       <div className="row">
          <div className="col-md-4">
             <div className="service_blog">
                <div className="service_img">
                   <img className="img-responsive" src="images/products/gallery13.jpg" alt="#" />
                </div>
                <div className="service_head">
                   <h5>Hardwares</h5>
                </div>
             </div>
          </div>
          <div className="col-md-4">
             <div className="service_blog">
                <div className="service_img">
                   <img className="img-responsive" src="images/products/gallery01.jpg" alt="#" />
                </div>
                <div className="service_head">
                   <h5><a href="service_detail.html">Plywoods</a></h5>
                </div>
             </div>
          </div>
          <div className="col-md-4">
             <div className="service_blog">
                <div className="service_img">
                   <img className="img-responsive" src="images/products/gallery24.jpg" alt="#" />
                </div>
                <div className="service_head">
                   <h5><a href="service_detail.html">Floor Planning</a></h5>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
  )
}

export default WhatWeDo
