import React from 'react';

function Brand() {
  return (
    <div id="brands" className="section padding_layout_1" style={{ padding: '50px 0' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <div className="main_heading text_align_center heading_with_subtitle">
                <h2><span>Our Brands</span></h2>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="full">
              <ul className="brand_list">
                {/* Modified image paths */}
                {Array.from({ length: 27 }, (_, index) => (
                  <li key={index}>
                    <img
                      src={`images/products/b_${String(index + 1).padStart(2, '0')}.jpg`}
                      alt={`Brand ${index + 1}`}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brand;
