import React from 'react'

function WhoWeAre() {
  return (
    <div className="section padding_layout_1 light_silver gross_layout right_gross_layout">
    <div className="container">
       <div className="row">
          <div className="col-md-12">
             <div className="full">
                <div className="main_heading text_align_right">
                   <h2>Who we are</h2>
                </div>
             </div>
          </div>
       </div>
       <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-12 col-lg-8 text-align_left">
             <div className="full large_cont_p">
                <p>Deepak Plywood manufacturing is well equipped with machineries and resources to produce any variety of plywood. Raw materials are sourced indigenously as well as from overseas. Deepak Plywood is known for its quality products.
                </p>
                <p>We are supply in all Rajasthan, Jaipur & mostly to Vaishali Nagar, Sikar Road, Industrial Area, Jhotwara, Sanganer & V.K.I. Area.</p>
                <p>We have a team of professionals with a determination and stamina. As the market continues to expand, we are prepared to deliver this and more to our associates as well.</p>
                <p>We have our strong manufacturing base at different locations across the country, backed up by a strong motivated team. The same has been supported by our marketing offices in all Metropolitan and Cosmopolitan cities of India. Our head quarter is in Delhi, which is the most beautiful city and capital of India.</p>
               
             </div>
          </div>
       </div>
    </div>
 </div>
  )
}

export default WhoWeAre
