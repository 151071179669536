import React from 'react';
import { Carousel } from 'react-bootstrap';

function Slider() {
  // Define the total number of slides (images)
  const totalSlides = 8;

  return (
    <Carousel>
      {Array.from({ length: totalSlides }).map((_, index) => (
        <Carousel.Item key={index} interval={3000}>
          <img
            className="d-block w-100"
            src={`images/slider/slide${(index + 1).toString().padStart(2, '0')}.jpg`}
            alt={`Slide ${index + 1}`}
          />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Slider;
